import React from "react"

// Styles
import Styled, { TitleProps } from "./title.styled"

// Types
interface Props extends TitleProps {
  level?: number
}

const Title: React.FC<Props> = ({ level = 1, children, ...rest }) => {
  const validLevels = [1, 2, 3, 4]

  const TagType = validLevels.includes(level) ? `h${level}` : `h1`

  return (
    <Styled.Title {...rest}>
      {React.createElement(TagType, null, children)}
    </Styled.Title>
  )
}

export default Title
