import styled from "styled-components"
import Colors from "ui/colors"

const Styled = {
  Footer: styled.footer`
    background: ${Colors.offWhite};
    text-align: center;
    padding: 100px 50px;

    @media (max-width: 950px) {
      padding: 50px 25px;
      text-align: left;
    }
  `,

  Email: styled.p`
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 24px;
  `,
}

export default Styled
