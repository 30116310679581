import styled from "styled-components"
import Colors from "ui/colors"
import helpers, { HelpersProps } from "helpers"

export interface TitleProps extends HelpersProps {
  blue?: boolean
  white?: boolean
}
const Styled = {
  Title: styled.div<TitleProps>`
    color: ${({ blue, white }) =>
      blue ? Colors.primaryBlue : white ? Colors.white : Colors.black};

    ${helpers}

    @media (max-width: 950px) {
      text-align: left;
    }

    h1 {
      font-size: 58px;

      @media (max-width: 950px) {
        font-size: 42px;
      }
    }

    h2 {
      font-size: 42px;

      @media (max-width: 950px) {
        font-size: 34px;
      }
    }

    h3 {
      font-size: 36px;

      @media (max-width: 950px) {
        font-size: 30px;
      }
    }

    h4 {
      font-size: 24px;

      @media (max-width: 950px) {
        font-size: 20px;
      }
    }
  `,
}

export default Styled
