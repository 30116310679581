import styled from "styled-components"
import Colors from "src/ui/colors"

const Styled = {
  Button: styled.button<{ primary: boolean }>`
    padding: 20px 30px;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    color: ${({ primary }) => (primary ? Colors.lightBlue2 : Colors.white)};
    background: ${({ primary }) =>
      primary ? Colors.darkBlue : Colors.lightBlue};
    font-size: 20px;
    cursor: pointer;
    font-family: "Avenir Next", sans-serif;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: ${({ primary }) =>
        primary ? Colors.darkBlueAlt : Colors.lightBlueAlt};
    }
  `,
}

export default Styled
