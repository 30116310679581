import styled from "styled-components"
import helpers, { HelpersProps } from "src/helpers"

export interface ContainerProps extends HelpersProps {
  maxWidth?: number
}

const Styled = {
  Container: styled.div<ContainerProps>`
    ${helpers}
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "1440px")};
    width: 100%;
    margin: 0 auto;
    position: relative;
  `,
}

export default Styled
