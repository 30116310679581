import React from "react"

// Styles
import Styled from "./fade-in.styled"

const FadeInAnimation = ({ children }) => {
  return <Styled.FadeIn>{children}</Styled.FadeIn>
}

export default FadeInAnimation
