import React from "react"
import { HelpersProps } from "src/helpers"

// Styles
import Styled, { ContainerProps } from "./container.styled"

// Types
interface Props extends ContainerProps {
  maxWidth?: number
}

const Container: React.FC<Props> = ({ children, ...rest }) => {
  return <Styled.Container {...rest}>{children}</Styled.Container>
}

export default Container
