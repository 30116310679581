import React from "react"
import { navigate } from "gatsby"

// Styles
import Styled from "./button.styled"
import { isNullOrUndefined } from "util"

// Types
interface Props {
  primary?: boolean
  to?: string
}

const Button: React.FC<Props> = ({ to, primary, children, ...rest }) => {
  const navigateToPage = (): void => {
    navigate(to)
  }

  const handleClick = (): void => {
    to && navigateToPage()
  }

  return (
    <Styled.Button
      onClick={handleClick}
      primary={primary}
      role={to ? "link" : "button"}
      {...rest}
    >
      {children}
    </Styled.Button>
  )
}

export default Button
