import styled from "styled-components"
import Colors from "src/ui/colors"
import helpers from "helpers"

const Styled = {
  Text: styled.p<{ italic: boolean }>`
    font-size: 24px;
    color: ${Colors.darkGray};

    @media (max-width: 950px) {
      font-size: 20px;
      text-align: left;
      line-height: 40px;
    }

    ${({ italic }) =>
      italic &&
      `
          font-style: italic;
          line-height: 50px;

          @media (max-width: 950px) {
            font-size: 24px;
            line-height: 40px;
          }


    `}

    ${helpers}
  `,
}

export default Styled
