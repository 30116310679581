import styled from "styled-components"
import helpers, { HelpersProps } from "src/helpers"

const Styled = {
  Section: styled.section<HelpersProps>`
    ${helpers}
    padding: 100px;

    @media (max-width: 950px) {
      padding: 50px 25px;
    }
  `,
}

export default Styled
