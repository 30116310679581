import styled, { keyframes } from "styled-components"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Styled = {
  FadeIn: styled.div`
    animation: 0.5s ease-in-out ${fadeIn};
    width: 100%;
  `,
}

export default Styled
