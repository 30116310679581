import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import FocusTrap from "focus-trap-react"

// Components
import { Container } from "src/components/common"

// Assets
import Logo from "images/SyncTree-Logo.svg"
import Ham from "images/icons/Ham.svg"
import Close from "images/icons/Close.svg"

// Styles
import Styled from "./navbar.styled"

const MobileMenu = ({ toggleMobileMenu, renderLinks }) => {
  return (
    <FocusTrap>
      <Styled.MobileMenu>
        <Styled.CloseMenuButton
          aria-label="Close mobile menu"
          onClick={toggleMobileMenu}
        >
          <img src={Close} alt="Close icon" />
        </Styled.CloseMenuButton>
        <Styled.MobileLinks>
          {renderLinks({ renderItem: Styled.MobileLink })}
        </Styled.MobileLinks>
      </Styled.MobileMenu>
    </FocusTrap>
  )
}

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const { pathname } = useLocation()

  const toggleMobileMenu = () => {
    setMenuOpen(prevState => !prevState)
  }

  // !: Disable body scroll when menu is open
  useEffect(() => {
    document.body.style.overflowY = isMenuOpen ? "hidden" : "scroll"
  }, [isMenuOpen])

  const renderLinks = ({ renderItem: Tag }) => {
    return [
      { name: "home", href: "/" },
      { name: "services", href: "/services" },
      // { name: "case studies", href: "/case-studies/storybots" },
      // { name: "partners", href: "/partners" },
      { name: "our story", href: "/our-story" },
      { name: "work with us", href: "/work-with-us" },
    ].map(({ name, href }) => {
      // const basePath = pathname.split("/")[1]
      // const isActive = basePath !== "" && href.includes(basePath)
      const isActive = href === pathname

      return (
        <Tag tabIndex="1" active={isActive} key={name}>
          <Link to={href}>{name}</Link>
        </Tag>
      )
    })
  }

  return (
    <>
      {isMenuOpen && (
        <MobileMenu
          renderLinks={renderLinks}
          toggleMobileMenu={toggleMobileMenu}
        />
      )}
      <Styled.Navbar>
        <Container
          flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link to="/">
            <img src={Logo} alt="synctree logo" />
          </Link>
          <Styled.Links>
            {renderLinks({ renderItem: Styled.Link })}
          </Styled.Links>
          <Styled.OpenMobileNavBtn onClick={toggleMobileMenu}>
            <img src={Ham} alt="Open Mobile Menu" />
          </Styled.OpenMobileNavBtn>
        </Container>
      </Styled.Navbar>
    </>
  )
}

export default Navbar
