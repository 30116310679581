import React from "react"
import { HelpersProps } from "helpers"

// Styles
import Styled from "./text.styled"

interface TextProps extends HelpersProps {
  italic?: boolean
}

const Text: React.FC<TextProps> = ({ italic = false, children, ...rest }) => {
  return (
    <Styled.Text italic={italic} {...rest}>
      {children}
    </Styled.Text>
  )
}

export default Text
