import React from "react"
import { HelpersProps } from "src/helpers"

// Styles
import Styled from "./section.styled"

const Section: React.FC<HelpersProps> = ({ children, ...rest }) => {
  return <Styled.Section {...rest}>{children}</Styled.Section>
}

export default Section
