const Colors = {
  white: "#FFFFFF",
  offWhite: "#F4F3F3",
  black: "#000000",
  darkGray: "#4B4B4B",
  green: "#3AA813",
  primaryBlue: "#157CDB",
  darkBlue: "#191847",
  darkBlueAlt: " #0A0A1E",
  lightBlue: "#52A6F3",
  lightBlueAlt: "#157CDB",
  lightBlue2: "#BAEBFF",
}

export default Colors
