import { css } from "styled-components"

export interface HelpersProps {
  flex?: boolean
  flexDirection?: "row" | "column"
  justifyContent?: "space-between" | "space-around"
  alignItems?: "center" | "flex-start"
  textAlign?: "center" | "right" | "left"
  mb?: number
  mt?: number
  pb?: number
  pt?: number
  mVertical?: number
}

const helpers = css<HelpersProps>`
  ${({ flex }) => flex && `display: flex;`}

  ${({ flexDirection }) =>
    flexDirection && `flex-direction: ${flexDirection};`}

  ${({
    justifyContent,
  }) => justifyContent && `justify-content: ${justifyContent};`}

  ${({
    alignItems,
  }) => alignItems && `align-items: ${alignItems};`}

  ${({ textAlign }) =>
    textAlign && `text-align: ${textAlign};`}


  ${({ mb }) =>
    mb && `margin-bottom: ${mb}px !important;`}

  ${({ mt }) =>
    mt && `margin-top: ${mt}px !important;`}

  ${({ mVertical }) =>
    mVertical &&
    `
      margin-top: ${mVertical}px !important;
      margin-bottom: ${mVertical}px !important;
  `}

  ${({ pb }) => pb && `padding-bottom: ${pb}px !important;`}

  ${({ pt }) =>
    pt && `padding-top: ${pt}px !important;`}
`

export default helpers
