import styled from "styled-components"
import Img from "gatsby-image"
import Colors from "src/ui/colors"
import GatsbyImage from "gatsby-image"
import { Section } from "src/components/common"

const Styled = {
  CaseStudy: styled.section`
    text-align: center;

    @media (max-width: 950px) {
      text-align: left;
    }
  `,

  Section: styled(Section)`
    padding-bottom: 0;
  `,

  Mockup: styled(Img)<GatsbyImage>`
    margin: 75px auto;
    display: block;
  `,

  MockupWrapper: styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;

    button {
      background: none;
      border: none;
      cursor: pointer;
      align-self: flex-start;
      margin-top: 15%;

      @media (max-width: 950px) {
        display: none;
      }

      &:first-of-type {
        margin-right: 5%;
      }

      &:last-of-type {
        margin-left: 5%;
      }

      img {
        width: 100%;
      }
    }
  `,

  TitleWrapper: styled.div`
    @media (max-width: 950px) {
      display: flex;
      margin-left: -15px;
    }
  `,

  MobileArrows: styled.div`
    display: none;

    @media (max-width: 950px) {
      display: flex;
    }

    button {
      border: none;
      background: none;
      margin: 10px 5px 0;

      img {
        height: 30px;
      }
    }
  `,

  About: styled.p`
    max-width: 975px;
    margin: 25px auto;
    font-size: 24px;
    color: ${Colors.darkGray};

    @media (max-width: 950px) {
      font-size: 20px;
    }
  `,

  Services: styled.p`
    font-size: 18px;
    color: ${Colors.green};
    font-weight: 600;
    margin-bottom: 25px;
  `,
}

export default Styled
