import styled from "styled-components"

import Colors from "../../ui/colors"

const Styled = {
  Navbar: styled.nav`
    background: ${Colors.primaryBlue};
    color: ${Colors.white};
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 135px;

    @media (max-width: 1165px) {
      padding: 25px;
    }

    img {
      margin: 0;
    }
  `,

  Links: styled.ul`
    list-style: none;

    @media (max-width: 1165px) {
      display: none;
    }
  `,

  Link: styled.li<{ active: boolean }>`
    text-transform: uppercase;
    font-size: 20px;
    display: inline-block;
    margin-right: 40px;

    &:hover {
      opacity: 0.65;
    }

    ${({ active }) =>
      active &&
      `
      border-bottom: 4px solid white;
    `}

    &:last-child {
      margin-right: 0;
    }

    a {
      color: inherit;
      text-decoration: none;
      padding: 4px 0;
      border-bottom: 4px solid transparent;
    }
  `,

  OpenMobileNavBtn: styled.button`
    display: none;
    border: none;
    background: none;
    height: 25px;
    bottom: 5px;
    position: relative;

    img {
      height: 100%;
    }

    @media (max-width: 1165px) {
      display: block;
    }
  `,

  MobileMenu: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: ${Colors.primaryBlue};
    z-index: 999;
    color: white;
    text-align: center;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  MobileLinks: styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  `,

  MobileLink: styled.li<{ active: boolean }>`
    padding: 20px 0;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    font-size: 20px;
    display: inline-block;
    opacity: 1;

    &:hover {
      opacity: 0.65;
    }

    a {
      color: inherit;
      text-decoration: none;
      padding: 4px 0;

      ${({ active }) =>
        active &&
        `
          border-bottom: 4px solid white;
        `}
    }
  `,

  CloseMenuButton: styled.button`
    position: absolute;
    top: 15px;
    left: 15px;
    background: none;
    border-style: none;
    height: 25px;

    img {
      height: 100%;
    }
  `,
}

export default Styled
