import React from "react"

// Components
import { Button } from "components/common"
import { Title, Text } from "components/typography"

// Styles
import Styled from "./footer.styled"

// Types
interface Props {
  heading: string
}

const Footer = ({ heading }: Props) => {
  return (
    <Styled.Footer>
      <Title level={3} mb={25}>
        {heading}
      </Title>
      <Styled.Email>hello@synctree.com</Styled.Email>
      <Text mb={25}>45 S Arroyo Parkway Pasadena, CA 91105</Text>

      <Button to="/work-with-us">Schedule a chat</Button>
    </Styled.Footer>
  )
}

export default Footer
