import React from "react"
import { navigate } from "gatsby"

// Components
import { Button, Container, FadeIn } from "src/components/common"
import { Title, Text } from "src/components/typography"

// Assets
import ArrowRight from "src/images/icons/Arrow-Right.svg"
import ArrowLeft from "src/images/icons/Arrow-Left.svg"
import ChevronRight from "src/images/icons/chevron-right.svg"
import ChevronLeft from "src/images/icons/chevron-left.svg"

// Styles
import Styled from "./case-study.styled"

const CaseStudy = ({
  about,
  caseStudyTitle,
  imageData,
  sectionTitle,
  services,
  slug,
  subtitle,
  changeCaseStudy,
}) => {
  return (
    <Styled.CaseStudy>
      <Container>
        <Styled.Section>
          <Styled.TitleWrapper>
            <Styled.MobileArrows>
              {changeCaseStudy && (
                <button
                  aria-label="View previous case study"
                  onClick={changeCaseStudy("left")}
                >
                  <img src={ChevronLeft} alt="arrow left" />
                </button>
              )}

              <Title textAlign="center" level={2} blue>
                {sectionTitle}
              </Title>

              {changeCaseStudy && (
                <button
                  aria-label="View next case study"
                  onClick={changeCaseStudy("right")}
                >
                  <img src={ChevronRight} alt="arrow right" />
                </button>
              )}
            </Styled.MobileArrows>
          </Styled.TitleWrapper>

          <Styled.MockupWrapper>
            {changeCaseStudy ? (
              <button
                aria-label="View previous case study"
                onClick={changeCaseStudy("left")}
              >
                <img src={ArrowLeft} alt="arrow left" />
              </button>
            ) : (
              <div />
            )}
            <FadeIn>
              <Styled.Mockup
                fluid={imageData}
                alt={`${caseStudyTitle} app screenshot`}
              />
            </FadeIn>

            {changeCaseStudy ? (
              <button
                aria-label="View next case study"
                onClick={changeCaseStudy("right")}
              >
                <img src={ArrowRight} alt="arrow right" />
              </button>
            ) : (
              <div />
            )}
          </Styled.MockupWrapper>

          <FadeIn>
            {caseStudyTitle && (
              <Title level={3} mb={15}>
                {caseStudyTitle}
              </Title>
            )}
            <Title level={4}>{subtitle}</Title>

            <Text mVertical={25}>{about}</Text>

            <Styled.Services>
              {services.map(({ service }) => service).join(", ")}
            </Styled.Services>

            {/* {!!slug && (
              <Button
                to={slug}
                aria-label={`Learn more ${caseStudyTitle} case study`}
              >
                Learn more
              </Button>
            )} */}
          </FadeIn>
        </Styled.Section>
      </Container>
    </Styled.CaseStudy>
  )
}

export default CaseStudy
